import { imageSizes } from '~/config/core'
const responsiveImg = (sources) => {
  if (!sources) { return '' }
  const widthWindow = window.innerWidth
  let size = ''
  const customImageSizes = {
    xs: 0,
    ...imageSizes
  }
  for (let i = 0; i < Object.keys(customImageSizes).length; i++) {
    if (i === Object.keys(customImageSizes).length - 1) {
      size = 'xxl'
      break
    } else if (widthWindow >= customImageSizes[Object.keys(customImageSizes)[i]] && widthWindow < customImageSizes[Object.keys(customImageSizes)[i + 1]]) {
      size = Object.keys(customImageSizes)[i]
      break
    }
  }
  let imageResult = ''
  if (sources.find(s => s.size === size)) {
    imageResult = sources.find(s => s.size === size).url
  } else if (size === 'xs' && sources.find(s => s.size === 'sm')) {
    imageResult = sources.find(s => s.size === 'sm').url
  } else {
    imageResult = sources.find(s => s.size === 'default').url
  }
  return imageResult
}
export {
  responsiveImg
}
