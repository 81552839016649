//
//
//
//

import { mapGetters } from 'vuex'
export default {
  layout: 'empty',
  computed: {
    ...mapGetters({ content: 'contents/getContentData' })
  }
}
